.userMenuHeader {
  background: rgba(0, 0, 0, 0.25);
  color: rgb(255, 255, 255);
  padding: 5px;
  font-size: 15px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.userMenuContent {
  display: flex;
  justify-content: center;
  padding: 10px;
}

:global(.ui.popup).userMenu {
  background: rgb(8, 52, 84) !important;
  border-color: rgb(186, 186, 188) !important;
  padding: 0px !important;
}

:global(.ui.popup).userMenu:before {
  background: rgb(8, 52, 84) !important;
  z-index: -1 !important;
}
