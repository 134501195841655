.announce {
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: #008eff;
  width: 500px;
  max-width: 100%;
  position: fixed;
  top: 0px;
  left: 50%;
  transform: translate(-50%, 0);
  border-radius: 4px;
  z-index: 900;
  height: 48px;
  color: white;
  overflow: hidden;
}

@media only screen and (max-width: 600px) {
  .announce {
    bottom: 0px;
    top: initial;
  }
}

.announce main {
  padding: 6px 10px;
  flex-grow: 1;
}

.announce main a {
  color: blue;
  text-decoration: underline;
}

.announce aside {
  padding: 6px 10px;
}
