html body {
  margin: 0;
  /* fix for scrollbar popping in and out at certain widths, but adds a permanent scrollbar */
  overflow-y: scroll;
  background-color: #222222;
}

::-webkit-scrollbar-corner {
  background: rgba(0, 0, 0, 0);
}

/* If a style is used outside of App, put it here */
button.linkButton {
  background: none !important;
  border: none;
  padding: 0 !important;
  color: #069;
  text-decoration: underline;
  cursor: pointer;
  /* make the font match the rest of the text */
  font-family: inherit;
}

.footerIcon {
  color: white;
  margin-right: 10px;
}

.toolButton {
  min-width: 135px;
  height: 36px;
}

.smallText {
  font-size: 12px;
}

.sectionHeader {
  font-size: 14px;
  text-transform: uppercase;
  font-weight: bolder;
  color: gray;
  margin-top: 8px;
}

/* Override default semantic ui styles below */
.ui .comment {
  word-break: break-word;
}

.ui.labeled.icon.button > .icon.loading,
.ui.labeled.icon.buttons > .button > .icon.loading {
  background-color: initial;
}

.ui.dropdown .menu[role='listbox'] {
  z-index: 1001;
}

.ui.selection.dropdown.ui.selection.dropdown {
  min-height: 1em;
  border: 0px;
}

.ui.search.dropdown > .text {
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 100%;
  overflow: hidden;
}

.ui.dropdown > .text {
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 100%;
  overflow: hidden;
}

.ui.inverted.menu .active.item {
  border-bottom: 2px solid #2185d0;
  background: inherit;
  margin-bottom: -2px;
}
