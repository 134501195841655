:global(#leftVideo)::-webkit-media-controls {
  display: none !important;
}

.videoContent {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.playerContainer {
  height: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  /* Making this larger causes issues on mobile */
  min-height: 48vh;
}

@media only screen and (max-width: 600px) {
  .mobileStack {
    flex-direction: column;
    width: 100%;
    gap: 4px;
  }
}

.fullHeightColumn.fullHeightColumn.fullHeightColumn {
  height: calc(100vh - 62px);
  transition: width ease-out 0.5s;
}

.fullHeightColumnFullscreen.fullHeightColumnFullscreen.fullHeightColumnFullscreen {
  height: calc(100vh);
}
